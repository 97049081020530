<template>
  <v-container fill-height fluid id="login" style="overflow=hidden">
    <v-layout align-center justify-center column fill-height>
      <v-card-text class="text-xs-center">
        <v-layout row wrap fill-height align-center justify-center>
          <v-flex sm4>
            <lottie
              :options="defaultOptions"
              v-on:animCreated="handleAnimation"
            />
          </v-flex>
          <v-flex sm4>
            <v-card-text>
              <h1 class="display-3">404</h1>
              <h2 class="display-1">{{ $t("labels.pageNotFound") }}</h2>
              <span class="caption mt-2"
                >The page you requested "{{ $route.path }}"" was not
                found.</span
              >
              <div class="mt-2">
                <v-btn color="info" to="/">{{ $t("buttons.home") }}</v-btn>
              </div>
            </v-card-text>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-layout>
  </v-container>
</template>
<script>
import Lottie from "../components/lottie";
import * as animationData from "../assets/lottie/errorPage.json";

export default {
  data() {
    return {
      defaultOptions: { animationData: animationData.default },
      animationSpeed: 5.5
    };
  },
  components: {
    Lottie
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
    stop() {
      this.anim.stop();
    },
    play() {
      this.anim.play();
    },
    pause() {
      this.anim.pause();
    },
    onSpeedChange() {
      this.anim.setSpeed(this.animationSpeed);
    }
  }
};
</script>
